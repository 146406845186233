<template>
  <v-card class="mt-3">
    <v-card-title class="d-flex justify-space-between align-center">
      <span class="text-subtitle-1 font-weight-bold">List service items</span>

      <v-icon class="reload-item" v-if="items.length > 0" @click="reloadItem"
        >mdi-reload</v-icon
      >
    </v-card-title>

    <div class="w-100 mt-2">
      <v-list densen v-if="items.length > 0">
        <v-subheader>
          <span class="text-subtitle-1 font-weight-bold"
            >ITEMS [<b class="primary--text">{{ countItems }}</b
            >]</span
          >
        </v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="getListPatient(item.targetID)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div class="d-flex justify-space-between align-center">
                  <span style="overflow: hidden; text-overflow: ellipsis">{{
                    item.name
                  }}</span>
                  <v-badge inline :content="item.amount"></v-badge>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-alert v-else text type="info"> List is empty! </v-alert>

      <!-- Print the error -->
      <div
        v-if="loading"
        class="d-flex justify-center flex-column align-center"
        style="padding: 10px"
      >
        <div class="p-5">
          <span class="text-subtitle-1 text-primary font-weight-bold"
            >Loading items...</span
          >
        </div>
        <div style="padding: 10px">
          <v-progress-circular
            :size="40"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </div>

    <v-alert v-if="error" dense type="error" class="m-5">
      Can not retrieve data!
    </v-alert>
  </v-card>
</template>

<script>
export default {
  props: ["itemState"],
  data() {
    return {
      items: [],
      error: false,
      loading: true,
    };
  },
  mounted() {
    this.items = this.itemState.list;
    this.error = this.itemState.error;
    this.loading = this.itemState.loading;
    console.log(this.items);
  },
  watch: {
    itemState() {
      this.items = this.itemState.list;
      this.error = this.itemState.error;
      this.loading = this.itemState.loading;
    },
  },

  computed: {
    countItems() {
      return this.items.length;
    },
  },

  methods: {
    getListPatient: function (targetID) {
      this.$emit("getList", targetID);
    },

    reloadItem: function () {
      console.log("asdasd");
      this.$emit("reloadItem");
    },
  },
};
</script>

<style scoped>
.reload-item {
  cursor: pointer;
  font-size: 20px;
  transition: transform 10s;
}
.reload-item:hover {
  transform: rotate(180deg);
  color: #2196f3;
}
</style>