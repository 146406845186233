<template>
  <v-main class="pt-0 pl-2 pr-2" light>
    <v-container fluid>
      <div class="w-100">
        <v-card>
          <v-card-title>
            <span class="text-h6"> Fast test </span>
          </v-card-title> 
        </v-card>
      </div>     
      <v-row>
        <v-col lg="3" md="4" sx="12" sm="12">
          <!-- Choosing Type of item -->
          <v-card>
            <v-card-title>
              <span>Service/Ax/Procedure</span>
            </v-card-title>
            <v-card-actions>
              <div class="d-flex justify-center pl-2 pr-2 w-100">
                <v-select
                  class="mx-auto"
                  :items="listType"
                  label="Choose type"
                  outlined
                  @change="changeType"
                ></v-select>
              </div>
            </v-card-actions>
          </v-card>

          <!--  List all items that belongs to Type -->
          <ItemsType
            :itemState="itemTypeState"
            v-on:getList="retrieveList"
            v-on:reloadItem="reloadItemType"
          />
        </v-col>

        <v-col lg="9" md="8" sx="12" sm="12">
          <!-- List patient that belongs to item of type  -->
          <ListPatientItem
            :listPatientState="itemPatientState"
            :itemTarget="currentItemService"
            v-on:changeComplete="changeComplete"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ListPatientItem from "../../components/commonComponents/FastTest/ListPatientItem";
import ItemsType from "../../components/commonComponents/FastTest/ItemsType";
import FastTestServicer from "../../services/fastTest";
export default {
  components: {
    ListPatientItem,
    ItemsType,
  },
  data() {
    return {
      currentItemService: {
        typeID: 0, // typeID : Ax | Procedure | Service
        targetID: 0,
        name: "",
      },

      selectedItem: 1,

      listType: [
        {
          text: "Assessments",
          value: 1,
        },
        {
          text: "Procedures",
          value: 2,
        },
        {
          text: "Services",
          value: 3,
        },
      ],

      // state list patient component
      itemPatientState: {
        error: false,
        loading: false,
        list: [],
      },

      // state list item that belongs to Ax | Procedure | Service
      itemTypeState: {
        error: false,
        loading: false,
        list: [],
      },
    };
  },
  mounted() {},

  methods: {
    changeType: function (value) {
      this.itemTypeState = { ...this.itemTypeState, loading: true, list: [] };

      // update typeID base on change event
      this.currentItemService = { ...this.currentItemService, typeID: value };
      this.retrieveListItem();
    },

    // event for retrieve list patient that belongs to ItemID (AxID, ProcedureID, ServiceID)
    retrieveList: function (targetID) {
      console.log(targetID);
      // update current item
      this.currentItemService = {
        ...this.currentItemService,
        targetID: targetID,
      };
      console.log(this.currentItemService);
      this.itemPatientState = { ...this.itemPatientState, loading: true };

      this.retrieveListPatient();
    },

    // reload item from type
    reloadItemType() {
      this.itemTypeState = { ...this.itemTypeState, loading: true, list: [] };
      this.retrieveListItem();
    },

    changeComplete: function (orderID) {
      this.finishProcess(orderID);
    },

    // retrieve listPatient from service
    async retrieveListPatient() {
      var result = await FastTestServicer.getListPatientWithItemID(
        this.currentItemService
      );
      console.log(result);
      if (result.error) {
        this.itemPatientState = {
          ...this.itemPatientState,
          error: true,
          loading: false,
        };
        return;
      }
      this.itemPatientState = {
        loading: false,
        error: false,
        list: result.orders,
      };
      this.currentItemService = {
        ...this.currentItemService,
        name: result.description,
      };
    },

    // retrieve listItem of TypeID
    async retrieveListItem() {
      var result = await FastTestServicer.getListItemWithType(
        this.currentItemService.typeID
      );
      if (result.error) {
        this.itemTypeState = {
          ...this.itemTypeState,
          error: true,
          loading: false,
        };
        return;
      }
      this.itemTypeState = { list: result, error: false, loading: false };
    },

    // update complete status for Order
    async finishProcess(orderID) {
      var service = false;

      if (this.currentItemService.typeID === 3) {
        service = true;
      }

      var result = await FastTestServicer.updateCompleteStatusOrder(
        orderID,
        service
      );
      if (result.error) {
        this.itemPatientState = { ...this.itemPatientState, error: true };
        return;
      }

      // Update item PatientState when try to remove 1 element in list
      this.itemPatientState = {
        list: this.itemPatientState.list.filter((i) => i.orderID != orderID),
        error: false,
        loading: false,
      };

      // update amount of Iitem that we removes element the itemPatient
      this.itemTypeState = {
        ...this.itemTypeState,
        list: this.itemTypeState.list
          .map((i) => {
            if (i.targetID === this.currentItemService.targetID) {
              i.amount -= 1;
            }
            return i;
          })
          .filter((i) => i.amount !== 0),
      };
    },
  },
};
</script>

<style scoped>
</style>