<template>
  <v-card>
    <v-card-title>
      <div class="d-flex justify-space-between align-items w-100">
        <div>
          <span>List Patient</span>
          <span class="ml-2"
            >[<b class="blue--text">{{ itemTarget.name }}</b
            >]</span
          >
        </div>
        <div>
          <span>Count:</span>
          <b class="ml-2">
            <number :from="0" :to="countItemPatient" />
          </b>
        </div>
      </div>
    </v-card-title>
    <v-sheet>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-if="loading"
      ></v-progress-linear>

      <v-simple-table v-if="listPatient.length > 0" id="target-patient-item">
        <thead>
          <tr style="font-size: 16px; font-weight: bold; color: black">
            <th>Order</th>
            <th>Name</th>
            <th>Patient ID</th>
            <th>Age</th>
            <th>Type</th>
            <th>Time start</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listPatient" :key="index">
            <td class="order">{{ index + 1 }}</td>
            <td>{{ item.fullname }}</td>
            <td>{{ item.patientID }}</td>
            <td>{{ item.age }}</td>
            <td>
              <v-badge
                :content="retrieveItemName"
                color="primary"
                inline
              ></v-badge>
            </td>
            <td>{{ item.timeOrdered }}</td>
            <td>
              <v-btn
                color="error"
                class="p-3"
                depressed
                medium
                v-on:click="finishTask(item.orderID)"
                >Finish</v-btn
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-alert
        v-else
        icon="mdi-account-box-multiple-outline"
        prominent
        text
        type="info"
      >
        List is empty!
      </v-alert>

      <v-alert v-if="error" dense type="error" class="m-5">
        Something went wrong! Contact with your administrator
      </v-alert>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  props: ["listPatientState", "itemTarget"],
  data() {
    return {
      error: false,
      loading: true,
      itemDescription: "",
      itemTypeID: "",
      listPatient: [],
      itemDetail: {
        targetID: 0,
        typeID: 0,
        name: "",
      },
    };
  },

  beforeMount() {
    this.mappingData();
  },

  computed: {
    retrieveItemName() {
      var result = "";
      switch (this.itemDetail.typeID) {
        case 1:
          result = "Assessment";
          break;
        case 2:
          result = "Procedure";
          break;
        case 3:
          result = "Service";
          break;
        default:
          break;
      }
      return result;
    },

    countItemPatient() {
      return this.listPatient.length;
    },
  },
  methods: {
    finishTask: function (itemOrderID) {
      this.$emit("changeComplete", itemOrderID);
    },
    mappingData() {
      this.listPatient = this.listPatientState.list;
      this.error = this.listPatientState.error;
      this.loading = this.listPatientState.loading;
      this.itemDetail = this.itemTarget;
    },
  },

  watch: {
    listPatientState() {
      this.mappingData();
    },
    itemTarget() {
      this.itemDetail = this.itemTarget;
    },
  },
};
</script>

<style scoped>
#target-patient-item .order {
  font-weight: bold;
  text-decoration: underline;
}
#target-patient-item tr:hover {
  cursor: pointer;
}
</style>